import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, useMediaQuery } from "@mui/material";
import CalendarUpcomingTable2 from "./subview/calendarMobile";
import CalendarDesktop from "./subview/calendarDesktop";
import Loading from "../Loading";
import WeeklyTrainingSummary from "./WeeklyTraining/WeeklyTrainingSummary";
import { useCalendarData } from "./subview/calendarDataProvider";

function Calendar({ accessToken }) {
  const { user } = useAuth0();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { weeklyLoads, currentWeekSummary, data } = useCalendarData();

  return (
    <div className="calendar_section">
      <Box sx={{ width: '100%', mb: 3, px: 2 }}>
        <Box sx={{ maxWidth: '1200px', mx: 'auto' }}>
          <WeeklyTrainingSummary 
            weeklyLoads={weeklyLoads}
            currentWeekSummary={currentWeekSummary}
            trainingPlan={data?.newTrainingPlan}
          />
        </Box>
      </Box>
      <div className="calendar-container">
        <Box 
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {isMobile ? (
            <CalendarUpcomingTable2 user={user} />
          ) : (
            <CalendarDesktop user={user} accessToken={accessToken} />
          )}
        </Box>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Calendar, {
  onRedirecting: () => <Loading />,
});
