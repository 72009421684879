import React, { useState } from 'react';
import { Button, Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

const loadRazorpay = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

const RazorpaySubscription = ({
  accessToken,
  userEmail,
  onSubscriptionComplete,
  currency = 'INR',
  updateLocalSubscription
}) => {
  const [loading, setLoading] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  // If user ends with @a.com, treat it as test mode.
  const isTestMode = userEmail?.toLowerCase().endsWith('@a.com');

  // For demonstration, re-use test/live keys as needed:
  const RAZORPAY_KEY = isTestMode
    ? 'rzp_test_HaGbACYm1j6H2a'
    : 'rzp_live_3twxUiPBqmGwwH';

  const handleSubscription = async (plan) => {
    try {
      setLoading(true);
      const res = await loadRazorpay();

      if (!res) {
        alert('Razorpay SDK failed to load');
        setLoading(false);
        return;
      }

      // planType => 'monthly_inr' or 'yearly_inr' etc
      const planType = `${plan.toLowerCase()}_${currency.toLowerCase()}`;

      const result = await axios.post(
        'https://api2.enduroco.in/settings/subscription/create-order',
        {
          planType,
          isTestMode
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          params: {
            email: userEmail,
            isTestMode
          }
        }
      );

      const { subscriptionId, amount, currency: planCurrency } = result.data;

      const options = {
        key: RAZORPAY_KEY,
        subscription_id: subscriptionId,
        name: 'Enduroco',
        description: `${plan.charAt(0).toUpperCase() + plan.slice(1)} Plan Subscription - ${currency}`,
        amount: amount,
        currency: planCurrency,
        prefill: {
          email: userEmail
        },
        theme: {
          color: '#1976d2'
        },
        modal: {
          ondismiss: function () {
            setLoading(false);
            setProcessingMessage('');
          }
        },
        handler: async function (response) {
          try {
            setProcessingMessage('Processing your payment, please wait...');

            if (updateLocalSubscription) {
              const futureTime = plan === 'monthly' ? 30 : 365;
              updateLocalSubscription({
                currentPlan: {
                  tier: plan === 'monthly' ? 'Monthly' : 'Yearly',
                  status: 'active',
                  expiryDate: new Date(Date.now() + futureTime * 24 * 60 * 60 * 1000).toISOString(),
                  trial: {
                    isTrialActive: false,
                    trialEndDate: null
                  }
                }
              });
            }

            const verifyResult = await axios.post(
              'https://api2.enduroco.in/settings/subscription/verify-payment',
              {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_subscription_id: response.razorpay_subscription_id,
                razorpay_signature: response.razorpay_signature,
                isTestMode
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json'
                },
                params: {
                  email: userEmail,
                  isTestMode
                }
              }
            );

            if (verifyResult.data.success) {
              setShowSuccess(true);
              if (onSubscriptionComplete) {
                await onSubscriptionComplete();
              }
            } else {
              throw new Error('Verification failed');
            }
          } catch (error) {
            console.error('Payment verification failed:', error);
            alert('Payment verification failed. Please contact support.');
          } finally {
            setProcessingMessage('');
            setLoading(false);
          }
        }
      };

      if (isTestMode) {
        options.prefill = {
          ...options.prefill,
          contact: '9999999999',
          name: 'Test User'
        };
      }

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Subscription creation failed:', error);
      alert('Failed to initiate subscription. Please try again.');
      setLoading(false);
      setProcessingMessage('');
    }
  };

  // Local helper to display amounts for monthly/yearly given the currency
  const getPlanLabel = (plan) => {
    if (currency === 'INR') {
      if (plan === 'monthly') return 'Monthly Plan - ₹990';
      if (plan === 'yearly') return 'Yearly Plan - ₹9,599 (Save 20%)';
    } else {
      if (plan === 'monthly') return 'Monthly Plan - $12.99';
      if (plan === 'yearly') return 'Yearly Plan - $99 (Save ~36%)';
    }
    return 'Unknown Plan';
  };

  return (
    <Box sx={{ mt: 2 }}>
      {isTestMode && (
        <Box
          sx={{
            mb: 2,
            p: 2,
            bgcolor: 'warning.light',
            borderRadius: 1,
            border: 1,
            borderColor: 'warning.main'
          }}
        >
          <Typography variant="body2" color="warning.dark">
            Test Mode Active - Using test payment gateway
          </Typography>
        </Box>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <CircularProgress />
          {processingMessage && (
            <Typography variant="body2">
              {processingMessage}
            </Typography>
          )}
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Choose your subscription plan:
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubscription('monthly')}
              disabled={loading}
            >
              {getPlanLabel('monthly')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubscription('yearly')}
              disabled={loading}
            >
              {getPlanLabel('yearly')}
            </Button>
          </Box>
        </>
      )}

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowSuccess(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Subscription activated successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RazorpaySubscription;