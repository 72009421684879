import React from 'react';
import { Box, Typography, ButtonBase, Grid, Tooltip, styled } from '@mui/material';
import { TrendingUp, TrendingDown } from 'lucide-react';

const RateButton = styled(ButtonBase)(({ theme, isSelected, intensity }) => ({
  width: '100%',
  padding: '12px',
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.divider}`,
  backgroundColor: isSelected ? `${theme.palette.primary.main}10` : theme.palette.background.paper,
  transition: 'all 0.2s ease',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&:hover': {
    backgroundColor: isSelected ? `${theme.palette.primary.main}15` : theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    backgroundColor: theme.palette.action.disabledBackground,
  }
}));

const rampRates = [
  { 
    value: -40, 
    label: 'Very Aggressive',
    description: 'Maximum progression with high intensity',
    icon: TrendingUp,
    color: '#d32f2f'
  },
  { 
    value: -20, 
    label: 'Aggressive',
    description: 'Faster progression with challenging workouts',
    icon: TrendingUp,
    color: '#ed6c02'
  },
  { 
    value: 0, 
    label: 'Conservative',
    description: 'Balanced progression with moderate intensity',
    icon: TrendingUp,
    color: '#2e7d32'
  },
  { 
    value: 20, 
    label: 'Recovery',
    description: 'Reduced intensity for recovery periods',
    icon: TrendingDown,
    color: '#0288d1'
  },
  {
    value: 40,
    label: 'Very Easy',
    description: 'Minimal intensity for extended recovery',
    icon: TrendingDown,
    color: '#0277bd'
  }
];

const RampRateSelector = ({ value, onChange, disabled }) => {
  // Convert input value to number for comparison
  const numericValue = Number(value);

  return (
    <Grid container spacing={1.5}>
      {rampRates.map((rate) => {
        const Icon = rate.icon;
        const isSelected = numericValue === rate.value;

        return (
          <Grid item xs={12} sm={6} key={rate.value}>
            <Tooltip title={rate.description} placement="top">
              <RateButton
                onClick={() => !disabled && onChange({
                  target: {
                    name: 'targetformpct',
                    value: rate.value
                  }
                })}
                isSelected={isSelected}
                disabled={disabled}
              >
                <Icon 
                  size={20} 
                  style={{ 
                    color: isSelected ? rate.color : 'inherit',
                    transform: rate.value < 0 ? 'rotate(30deg)' : rate.value > 0 ? 'rotate(-30deg)' : 'none'
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      color: isSelected ? rate.color : 'text.primary',
                      lineHeight: 1.2
                    }}
                  >
                    {rate.label}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      display: 'block'
                    }}
                  >
                    {rate.description}
                  </Typography>
                </Box>
              </RateButton>
            </Tooltip>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default RampRateSelector;
