import React, { useState, useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    Container,
    AppBar,
    Toolbar
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import LinkIcon from '@mui/icons-material/Link';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import ProfileSection from '../settings2_profile';
import PreferencesSection from './PreferencesSection';
import ConnectionsSection from '../settings2_connections';
import SubscriptionSection from './SubscriptionSection';
import { IntensitySection } from '../settings2_trainingzones';
import { WorkoutPlanningWrapper, WorkoutUpdateStatus } from '../settings2_workoutPlanning';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from 'axios';
import Loading from '../../components/Loading';
import SectionHeader from './DynamicSectionHeader';
import MigrationBanner from './components/MigrationBanner';
import { useLocation, useNavigate } from 'react-router-dom';

const ResponsiveSettings = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [profileData, setProfileData] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [error, setError] = useState(null);

    const [selectedCategory, setSelectedCategory] = useState(0);
    const [workoutPlanningOption, setWorkoutPlanningOption] = useState(null);
    const [showDeleteEventDialog, setShowDeleteEventDialog] = useState(false);
    const [pendingWorkoutOption, setPendingWorkoutOption] = useState(null);
    const [isChatMode, setIsChatMode] = useState(false);

    const theme = useTheme();
    const isWideScreen = useMediaQuery(theme.breakpoints.up('md'));
    const location = useLocation();
    const navigate = useNavigate();

    // Define categories with optional `path` to match sub-URLs
    const categories = [
        {
            name: 'Profile & Preferences',
            icon: PersonIcon,
            subCategories: ['Profile', 'Preferences'],
            path: '' // main /settings
        },
        {
            name: 'Subscription',
            icon: SubscriptionsIcon,
            component: SubscriptionSection,
            path: 'subscription'
        },
        {
            name: 'Connections',
            icon: LinkIcon,
            subCategories: ['Connections'],
            path: 'connections'
        },
        {
            name: 'Workout Planning',
            icon: CalendarMonthIcon,
            path: 'workout-planning'
        },
        {
            name: 'Training Zones',
            icon: SpeedIcon,
            subCategories: ['Intensity'],
            path: 'training-zones'
        },
    ];

    // Sync the selected category index with the current URL path (location.pathname).
    useEffect(() => {
        // /settings
        // /settings/subscription
        // /settings/connections
        const segments = location.pathname.split('/');
        const lastSegment = segments[segments.length - 1];
        // If the path is exactly "/settings" or ends with "settings", show the first category (Profile & Preferences)
        if (lastSegment === 'settings') {
            setSelectedCategory(0);
            return;
        }

        const foundIndex = categories.findIndex(cat => cat.path === lastSegment);
        if (foundIndex > -1) {
            setSelectedCategory(foundIndex);
        } else {
            // Fallback to 0 if we don't match any known path
            setSelectedCategory(0);
        }
    }, [location.pathname, categories]);

    const handleCategoryClick = (index) => {
        setSelectedCategory(index);
        const cat = categories[index];
        // If category has a path, navigate to /settings/{path}, else /settings
        if (cat.path) {
            navigate(`/settings/${cat.path}`);
        } else {
            navigate('/settings');
        }
    };

    const handleWorkoutOptionSelect = (option) => {
        if (option === 'custom' && profileData?.event?.name) {
            setShowDeleteEventDialog(true);
            setPendingWorkoutOption(option);
        } else {
            setWorkoutPlanningOption(option);
        }
    };

    useEffect(() => {
        if (profileData?.event?.name) {
            setWorkoutPlanningOption('event');
        } else {
            setWorkoutPlanningOption('custom');
        }
    }, [profileData]);

    const handleConfirmEventDelete = async () => {
        try {
            await axios.patch(
                'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
                { event: null },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    params: {
                        email: user.email
                    }
                }
            );
            setWorkoutPlanningOption('custom');
            setShowDeleteEventDialog(false);
            setPendingWorkoutOption(null);
            await fetchProfile(accessToken);
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteEventDialog(false);
        setPendingWorkoutOption(null);
    };

    const fetchProfile = async (token) => {
        try {
            const response = await axios.get(
                'https://api2.enduroco.in/settings',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    params: {
                        email: user.email
                    }
                }
            );
            setProfileData(response.data);
        } catch (err) {
            setError('Failed to fetch profile data');
            console.error(err);
        }
    };

    useEffect(() => {
        if (!user) return;

        const initializeProfile = async () => {
            try {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
                await fetchProfile(token);
            } catch (err) {
                setError('Failed to initialize profile');
                console.error(err);
            }
        };
        initializeProfile();
    }, [getAccessTokenSilently, user]);

    useEffect(() => {
        if (isWideScreen) {
            // On wide screen, default to first category if none selected
            // (We do a path-based approach now, but let's keep it as a fallback)
            if (selectedCategory < 0) {
                setSelectedCategory(0);
            }
        }
    }, [isWideScreen, selectedCategory]);

    const checkMigrationStatus = (data) => {
        return data?.profile?.isMigrated === true;
    };

    const getComponentForCategory = (category) => {
        switch (category) {
            case 'Profile':
                return (
                    <ProfileSection
                        profile={profileData.profile}
                        accessToken={accessToken}
                        onRefresh={fetchProfile}
                        isChatMode={isChatMode}
                    />
                );
            case 'Preferences':
                return (
                    <PreferencesSection
                        preferences={profileData.preferences}
                        accessToken={accessToken}
                        userEmail={user.email}
                        onRefresh={fetchProfile}
                    />
                );
            case 'Connections':
                return (
                    <ConnectionsSection
                        connections={profileData.connections}
                        accessToken={accessToken}
                        onRefresh={fetchProfile}
                        subscription={profileData.subscription}
                    />
                );
            case 'Intensity':
                return (
                    <IntensitySection
                        workoutIntensity={profileData.workoutIntensity}
                        accessToken={accessToken}
                        userEmail={user.email}
                        onRefresh={fetchProfile}
                    />
                );
            case 'Subscription':
                return (
                    <SubscriptionSection
                        subscription={profileData.subscription}
                        accessToken={accessToken}
                        userEmail={user.email}
                    />
                );
            default:
                return null;
        }
    };

    const renderSection = (categoryName, subCategory = null) => {
        if (categoryName === 'Workout Planning') {
            return (
                <WorkoutPlanningWrapper
                    workoutPlanningOption={workoutPlanningOption}
                    handleWorkoutOptionSelect={handleWorkoutOptionSelect}
                    profileData={profileData}
                    accessToken={accessToken}
                    userEmail={user.email}
                    fetchProfile={fetchProfile}
                    showDeleteEventDialog={showDeleteEventDialog}
                    handleConfirmEventDelete={handleConfirmEventDelete}
                    handleCloseDeleteDialog={handleCloseDeleteDialog}
                />
            );
        }

        if (subCategory) {
            return getComponentForCategory(subCategory);
        }

        return getComponentForCategory(categoryName);
    };

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!user || !profileData) {
        return <Loading />;
    }

    const selectedCategoryData = categories[selectedCategory];

    return (
        <Box
            sx={{
                bgcolor: theme.palette.background.default,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <AppBar position="sticky" color="primary" elevation={1}>
                <Container maxWidth="lg">
                    <Toolbar sx={{ minHeight: { xs: 48, sm: 64 } }}>
                        <SettingsIcon sx={{ mr: 2 }} />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, fontWeight: 'bold' }}
                        >
                            Settings
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container
                maxWidth="lg"
                sx={{
                    mt: 4,
                    mb: 4,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {profileData && !checkMigrationStatus(profileData) && (
                    <MigrationBanner
                        accessToken={accessToken}
                        userEmail={user.email}
                        onMigrationComplete={() => fetchProfile(accessToken)}
                    />
                )}

                {profileData?.workoutUpdateStatus && (
                    <WorkoutUpdateStatus workoutUpdateStatus={profileData.workoutUpdateStatus} />
                )}

                {isWideScreen ? (
                    <Paper
                        elevation={3}
                        sx={{
                            display: 'flex',
                            flex: 1,
                            overflow: 'hidden',
                            borderRadius: theme.shape.borderRadius,
                            height: 'calc(100vh - 180px)',
                            position: 'relative'
                        }}
                    >
                        <Box
                            sx={{
                                width: '25%',
                                borderRight: `1px solid ${theme.palette.divider}`,
                                bgcolor: theme.palette.background.paper,
                                overflowY: 'auto'
                            }}
                        >
                            <List component="nav" aria-label="settings categories">
                                {categories.map((cat, index) => (
                                    <ListItem
                                        button
                                        key={cat.name}
                                        selected={selectedCategory === index}
                                        onClick={() => handleCategoryClick(index)}
                                        sx={{
                                            borderBottom: `1px solid ${theme.palette.divider}`,
                                            '&.Mui-selected': {
                                                bgcolor: theme.palette.action.selected,
                                                borderLeft: `4px solid ${theme.palette.primary.main}`,
                                            },
                                            '&:hover': {
                                                bgcolor: theme.palette.action.hover,
                                            },
                                        }}
                                    >
                                        <ListItemIcon>
                                            <cat.icon color={selectedCategory === index ? 'primary' : 'action'} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <SectionHeader
                                                    categoryName={cat.name}
                                                    sectionData={profileData}
                                                />
                                            }
                                            primaryTypographyProps={{
                                                fontWeight: selectedCategory === index ? 'bold' : 'normal',
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        {selectedCategoryData && (
                            <Box
                                sx={{
                                    width: '75%',
                                    p: 3,
                                    overflowY: 'auto',
                                    height: '100%'
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}
                                >
                                    {selectedCategoryData.name}
                                </Typography>
                                {selectedCategoryData.subCategories ? (
                                    selectedCategoryData.subCategories.map((subCategory) => (
                                        <Box key={subCategory} sx={{ mb: 4 }}>
                                            {renderSection(selectedCategoryData.name, subCategory)}
                                        </Box>
                                    ))
                                ) : (
                                    renderSection(selectedCategoryData.name)
                                )}
                            </Box>
                        )}
                    </Paper>
                ) : (
                    <Paper
                        elevation={3}
                        sx={{
                            overflow: 'hidden',
                            borderRadius: theme.shape.borderRadius,
                            maxHeight: 'calc(100vh - 180px)',
                            overflowY: 'auto'
                        }}
                    >
                        {categories.map((cat, index) => (
                            <Accordion
                                key={cat.name}
                                disableGutters
                                expanded={selectedCategory === index}
                                onChange={() => setSelectedCategory((prev) => (prev === index ? -1 : index))}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${cat.name}-content`}
                                    id={`${cat.name}-header`}
                                    sx={{
                                        bgcolor: selectedCategory === index
                                            ? theme.palette.action.hover
                                            : theme.palette.background.paper,
                                        '&.Mui-expanded': {
                                            bgcolor: theme.palette.action.selected,
                                        },
                                    }}
                                    onClick={() => handleCategoryClick(index)}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <cat.icon sx={{ mr: 2, color: theme.palette.primary.main }} />
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            {cat.name}
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box>
                                        {cat.subCategories ? (
                                            cat.subCategories.map((subCategory) => (
                                                <Box key={subCategory} sx={{ mb: 3 }}>
                                                    {renderSection(cat.name, subCategory)}
                                                </Box>
                                            ))
                                        ) : (
                                            renderSection(cat.name)
                                        )}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Paper>
                )}
            </Container>
        </Box>
    );
};

export default withAuthenticationRequired(ResponsiveSettings, {
    onRedirecting: () => <Loading />,
});