import moment from 'moment';

const mapWorkoutsToEvents = (data) => {
  if (!data) return [];
  
  console.log('Mapping workouts to events:', { data });
  
  let events = [];

  // Process current workouts
  if (data.workouts) {
    console.log('Processing current workouts');
    data.workouts.forEach(day => {
      if (day.workouts) {
        day.workouts.forEach(workout => {
          console.log('Processing workout:', { date: day.workoutdate, workout });
          events.push({
            ...workout,
            date: day.workoutdate,
            eventtype: workout.type === 'activity' ? 'activity' : 'workout'
          });
        });
      }
    });
  }

  // Process historical workouts
  if (data.workoutHistory) {
    console.log('Processing historical workouts:', { historyLength: data.workoutHistory.length });
    data.workoutHistory.forEach(historyDay => {
      if (historyDay.workouts) {
        historyDay.workouts.forEach(dayData => {
          if (dayData.workouts) {
            dayData.workouts.forEach(workout => {
              console.log('Processing historical workout:', {
                date: dayData.workoutdate,
                workout: workout.title
              });
              
              events.push({
                ...workout,
                date: dayData.workoutdate,
                eventtype: workout.type === 'activity' ? 'activity' : 'workout',
                isHistorical: true,
                fitness: dayData.fitness,
                form: dayData.form,
                tss: dayData.tss
              });
            });
          }
        });
      }
    });
  }

  console.log('Total mapped events:', events.length);
  return events;
};

export default mapWorkoutsToEvents;