import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import CollapsibleSection from './CollapsibleSection';
import WeekCard from './components/WeekCard';

const WeeklyTrainingSummary = ({ weeklyLoads, currentWeekSummary, trainingPlan }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const processedWeeks = React.useMemo(() => {
    if (trainingPlan) {
      return trainingPlan.map((week, index) => ({
        weekNumber: index + 1,
        weekStart: week.startDate,
        weekEnd: week.endDate,
        baselineWeeklyTSS: week.baselineWeeklyTSS,
        weeklyTargetTSS: week.weeklyTargetTSS,
        currentWeekLoad: week.completedWeekTSS || 0,
        remainingWeekTSS: week.remainingWeekTSS || 0,
        isRecoveryWeek: week.weeklyTargetTSS < (index > 0 ? trainingPlan[index - 1].weeklyTargetTSS : week.weeklyTargetTSS),
        dailyLoads: week.days.map(day => ({
          date: day.workoutdate,
          load: day.load,
          workouts: day.workouts.map(workout => ({
            ...workout,
            discipline: workout.discipline || workout.type.toLowerCase()
          }))
        }))
      }));
    }

    return weeklyLoads.map(week => ({
      weekNumber: week.weekNumber,
      weekStart: week.weekStart,
      weekEnd: week.weekEnd,
      baselineWeeklyTSS: week.baselineLoad,
      weeklyTargetTSS: week.adjustedLoad,
      currentWeekLoad: week.currentWeekLoad || 0,
      remainingWeekTSS: week.remainingWeekLoad,
      isRecoveryWeek: week.isRecoveryWeek,
      phase: week.phase,
      phaseDescription: week.phaseDescription,
      dailyLoads: week.dailyLoads || []
    }));
  }, [trainingPlan, weeklyLoads]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  const formatTabLabel = (week) => {
    const startDate = moment(week.weekStart).format('MMM D');
    const endDate = moment(week.weekEnd).format('MMM D');
    const totalAchievableLoad = week.currentWeekLoad + week.weeklyTargetTSS;
    
    if (isMobile) {
      return `${startDate}-${moment(week.weekEnd).format('D')} • ${totalAchievableLoad}`;
    }
    
    return `${startDate} - ${endDate} • ${week.isRecoveryWeek ? 'Recovery' : 'Training'} • ${totalAchievableLoad}/${week.baselineWeeklyTSS}`;
  };

  if (!processedWeeks?.length) return null;

  return (
    <CollapsibleSection title="Training Load Summary">
      <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs
            value={currentTabIndex}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              minHeight: '36px',
              '& .MuiTab-root': {
                minHeight: '36px',
                fontSize: '0.75rem',
                fontWeight: 500,
                textTransform: 'none',
                minWidth: isMobile ? '120px' : '200px'
              }
            }}
          >
            {processedWeeks.map((week, index) => (
              <Tab
                key={week.weekStart}
                label={formatTabLabel(week)}
                id={`week-tab-${index}`}
                aria-controls={`week-tabpanel-${index}`}
                sx={{
                  color: week.isRecoveryWeek ? theme.palette.info.main : theme.palette.primary.main
                }}
              />
            ))}
          </Tabs>
        </Box>

        <Box sx={{ mt: 2 }}>
          {processedWeeks.map((week, index) => (
            <Box
              key={week.weekStart}
              role="tabpanel"
              hidden={currentTabIndex !== index}
              id={`week-tabpanel-${index}`}
              aria-labelledby={`week-tab-${index}`}
              sx={{
                transition: 'opacity 0.3s ease',
                opacity: currentTabIndex === index ? 1 : 0,
                display: currentTabIndex === index ? 'block' : 'none'
              }}
            >
              <WeekCard
                week={week}
                isCurrentWeek={moment().isBetween(
                  moment(week.weekStart),
                  moment(week.weekEnd),
                  'day',
                  '[]'
                )}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </CollapsibleSection>
  );
};

export default WeeklyTrainingSummary;
