import React from 'react';
import { Box, Paper, Typography, Grid, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { getIntensityColor, getDisciplineIcon } from '../utils/weeklyTrainingUtils';

const WeekCard = ({ week, isCurrentWeek }) => {
  const theme = useTheme();
  
  const completedLoad = week.currentWeekLoad || 0;
  const maxPossibleLoad = week.weeklyTargetTSS || 0;
  const baselineLoad = week.baselineWeeklyTSS || 0;
  const unreachableLoad = week.remainingWeekTSS - maxPossibleLoad;
  const totalAchievableLoad = completedLoad + maxPossibleLoad;
  
  // Progress relative to what's actually achievable this week
  const progressPercentage = Math.round((completedLoad / totalAchievableLoad) * 100);

  return (
    <Paper
      elevation={isCurrentWeek ? 3 : 1}
      sx={{
        p: 1.5,
        backgroundColor: isCurrentWeek
          ? theme.palette.grey[50]
          : theme.palette.background.paper,
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
        }
      }}
    >
      {isCurrentWeek && (
        <Box sx={{ mb: 1 }}>
          {/* Overall Weekly Target Info */}
          <Box sx={{ mb: 1 }}>
            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.75rem', mb: 0.5 }}>
              Base Weekly Target: {baselineLoad} Load
            </Typography>
          </Box>

          {/* Progress Section */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.75rem' }}>
              Progress: {progressPercentage}%
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.75rem' }}>
              {completedLoad} / {totalAchievableLoad} Load
            </Typography>
          </Box>

          {/* Progress Bar */}
          <Box
            sx={{
              width: '100%',
              height: '6px',
              backgroundColor: theme.palette.grey[200],
              borderRadius: '3px',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            {/* Completed Load */}
            <Box
              sx={{
                width: `${(completedLoad / baselineLoad) * 100}%`,
                height: '100%',
                backgroundColor: theme.palette.success.main,
                position: 'absolute',
                left: 0
              }}
            />
            {/* Achievable Remaining Load */}
            <Box
              sx={{
                width: `${(maxPossibleLoad / baselineLoad) * 100}%`,
                height: '100%',
                backgroundColor: theme.palette.warning.main,
                position: 'absolute',
                left: `${(completedLoad / baselineLoad) * 100}%`,
                opacity: 0.5
              }}
            />
            {/* Unreachable Load */}
            <Box
              sx={{
                width: `${(unreachableLoad / baselineLoad) * 100}%`,
                height: '100%',
                backgroundColor: theme.palette.error.main,
                position: 'absolute',
                right: 0,
                opacity: 0.3
              }}
            />
          </Box>

          {/* Load Breakdown */}
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant="body2" sx={{ fontSize: '0.75rem', color: theme.palette.warning.main }}>
              Achievable This Week: {maxPossibleLoad} Load
            </Typography>
            {unreachableLoad > 0 && (
              <Typography variant="body2" sx={{ fontSize: '0.75rem', color: theme.palette.error.main }}>
                Unreachable Load: {unreachableLoad} Load
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {/* Daily Workouts */}
      {week.dailyLoads && (
        <Box
          sx={{
            mt: 0.5,
            maxWidth: '600px',
            mx: 'auto',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1,
            overflow: 'hidden'
          }}
        >
          <Grid container spacing={0}>
            {week.dailyLoads.map((day, index) => (
              <Grid item xs={12} key={day.date}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    py: 0.5,
                    px: 1,
                    borderBottom: index < week.dailyLoads.length - 1 ? `1px solid ${theme.palette.divider}` : 'none',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover
                    },
                    minHeight: '32px'
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      width: '36px',
                      flexShrink: 0,
                      fontSize: '0.75rem'
                    }}
                  >
                    {moment(day.date).format('ddd')}
                  </Typography>

                  <Box sx={{
                    display: 'flex',
                    gap: 0.5,
                    flexWrap: 'wrap',
                    flex: 1,
                    alignItems: 'center'
                  }}>
                    {day.workouts?.map((workout, wIndex) => {
                      const intensityColor = getIntensityColor(workout.intensity, theme);
                      return (
                        <Chip
                          key={wIndex}
                          icon={getDisciplineIcon(workout.discipline || workout.type, intensityColor)}
                          label={`${workout.type}${workout.tss ? ` ${workout.tss}` : ''}`}
                          size="small"
                          sx={{
                            height: '20px',
                            backgroundColor: `${intensityColor}15`,
                            color: intensityColor,
                            '& .MuiChip-icon': {
                              ml: '4px',
                              mr: '-4px'
                            },
                            '& .MuiChip-label': {
                              fontSize: '0.7rem',
                              px: 1,
                              py: 0
                            }
                          }}
                        />
                      );
                    })}
                  </Box>

                  {day.load > 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        ml: 1,
                        flexShrink: 0
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 500,
                          fontSize: '0.75rem',
                          color: theme.palette.text.secondary
                        }}
                      >
                        Load:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 600,
                          fontSize: '0.75rem',
                          color: theme.palette.primary.main
                        }}
                      >
                        {day.load}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Paper>
  );
};

export default WeekCard;
