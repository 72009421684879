import React, { createContext, useContext } from "react";
import useSWR from "swr";
import { useFetcher } from "../../../utils/fetcher";

const DataContext = createContext();

export function useCalendarData() {
  return useContext(DataContext);
}

export const CalendarDataProvider = ({ user, children }) => {
  const { fetcher, accessToken } = useFetcher();
  const { data, error } = useSWR(
    accessToken ? `https://api2.enduroco.in/getworkouts?` : null,
    fetcher
  );

  const addDateAndDurationToWorkouts = (data) => {
    console.log('Processing calendar data:', {
      hasWorkouts: !!data?.workouts,
      hasHistory: !!data?.workoutHistory
    });

    if (!data) return data;

    // Process current workouts
    const updatedWorkouts = data.workouts ? data.workouts.map((day) => ({
      ...day,
      workouts: day.workouts.map((workout) => {
        const durationValue = workout.workout_doc?.duration;
        const formattedDuration =
          durationValue !== undefined
            ? `${Math.floor(durationValue / 3600)}h ${Math.floor(
              (durationValue % 3600) / 60
            )}m`
            : "";
        return {
          ...workout,
          date: day.workoutdate,
          readableDuration: formattedDuration,
          structure: workout.workout_doc,
        };
      }),
    })) : [];

    // Process historical workouts
    const processedHistory = data.workoutHistory ? data.workoutHistory.map(historyDay => ({
      ...historyDay,
      workouts: historyDay.workouts.map(day => ({
        ...day,
        workouts: day.workouts.map(workout => {
          const durationValue = workout.workout_doc?.duration;
          const formattedDuration =
            durationValue !== undefined
              ? `${Math.floor(durationValue / 3600)}h ${Math.floor(
                (durationValue % 3600) / 60
              )}m`
              : "";
          return {
            ...workout,
            date: day.workoutdate,
            readableDuration: formattedDuration,
            structure: workout.workout_doc,
            isHistorical: true
          };
        })
      }))
    })) : [];

    console.log('Processed data:', {
      currentWorkouts: updatedWorkouts.length,
      historicalWorkouts: processedHistory.length
    });

    return { 
      ...data, 
      workouts: updatedWorkouts,
      workoutHistory: processedHistory,
      weeklyLoads: data.weeklyLoads || [],
      currentWeekSummary: data.currentWeekSummary || null
    };
  };

  const processedData = data ? addDateAndDurationToWorkouts(data) : null;

  const value = { 
    data: processedData,
    error,
    plan: data?.plan,
    weeklyLoads: data?.weeklyLoads || [],
    currentWeekSummary: data?.currentWeekSummary
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};