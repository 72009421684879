import React from "react";
import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import CalendarEvent from "./CalendarEvent";
import Box from "@mui/material/Box";

const CalendarDay = ({
  day,
  events,
  setOpenWorkoutView,
  setSelectedEvent,
}) => {
  const theme = useTheme();
  const eventsList = events.filter((event) =>
    moment(event.date).isSame(day, "day")
  );
  const isToday = moment().isSame(day, "day");

  return (
    <Grid 
      xs={true} 
      sx={{
        width: `${100/7}%`,
        height: '100%',
        minHeight: '100px',
        textAlign: "center",
        borderRight: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: isToday
          ? theme.palette.secondary.main
          : theme.palette.background.paper,
        color: isToday
          ? theme.palette.secondary.contrastText
          : theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'column',
        padding: '2px',
        boxSizing: 'border-box',
        flex: '1 0 auto',
        overflow: 'hidden'  // Add this to prevent outer scrollbar
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Typography
          variant="caption"
          component="div"
          sx={{
            color: theme.palette.text.secondary,
            fontWeight: 500,
            fontSize: '0.75rem'
          }}
        >
          {day.format("ddd")}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{
            fontWeight: "bold",
            padding: '4px 0'
          }}
        >
          {day.format("D")}
        </Typography>
      </Box>
      <Box 
        sx={{
          flex: 1,
          width: '100%',
          overflowY: 'auto',
          paddingRight: '2px',  // Add slight padding for scrollbar
          marginRight: '-2px',  // Compensate for padding to maintain alignment
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.divider,
            borderRadius: '2px'
          },
          '&:hover': {
            paddingRight: '2px',  // Maintain padding on hover
          }
        }}
      >
        {eventsList.map((event, idx) => (
          <CalendarEvent
            event={event}
            setOpenWorkoutView={setOpenWorkoutView}
            setSelectedEvent={setSelectedEvent}
            key={idx}
            marginBottom={idx === eventsList.length - 1 ? "0px" : "5px"}
          />
        ))}
      </Box>
    </Grid>
  );
};

export default CalendarDay;
