import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    IconButton,
    Paper,
    Typography,
    TextField,
    InputAdornment,
    CircularProgress,
    Fab,
    Avatar,
    Tooltip,
    Collapse
} from '@mui/material';
import {
    Send as SendIcon,
    Message as MessageIcon,
    Close as CloseIcon,
    Check as CheckIcon,
    CheckCircle as CheckCircleIcon,
    MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';

const MessagePopup = ({ accessToken }) => {
    // Initialize to true so it pops up automatically.
    const [isOpen, setIsOpen] = useState(true);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(null);

    const messagesEndRef = useRef(null);
    const fetchIntervalRef = useRef(null);
    const popupRef = useRef(null);
    const inputRef = useRef(null);

    const apiBaseUrl = 'https://api2.enduroco.in';

    const welcomeMessage = {
        MessageID: 'welcome',
        Content: "👋 Hi! I'm your Enduro Coach. I'm here to help with your training and fitness goals. How can I assist you today?",
        Sender: 'System',
        Timestamp: new Date().toISOString(),
        Status: 'delivered'
    };

    const fetchMessages = async () => {
        if (!accessToken) return;

        try {
            const response = await fetch(`${apiBaseUrl}/messages/user`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            const data = await response.json();

            // If no prior messages, show the welcome message
            if (data.length === 0) {
                setMessages([welcomeMessage]);
            } else {
                // Add a 'delivered' status to each message
                const messagesWithStatus = data.map(msg => ({
                    ...msg,
                    Status: 'delivered'
                }));
                setMessages(messagesWithStatus);
            }

            setLoading(false);
            setInitialLoadComplete(true);
        } catch (err) {
            setError(err.message);
            setLoading(false);
            setInitialLoadComplete(true);
        }
    };

    // Close the popup when clicking outside it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Polling messages every 100 seconds while open
    useEffect(() => {
        if (isOpen) {
            fetchMessages();
            fetchIntervalRef.current = setInterval(fetchMessages, 100000);
        } else {
            clearInterval(fetchIntervalRef.current);
        }
        return () => clearInterval(fetchIntervalRef.current);
    }, [isOpen, accessToken]);

    // Fetch on component mount or token changes
    useEffect(() => {
        fetchMessages();
    }, [accessToken]);

    // Scroll to bottom on new messages
    const scrollToBottom = (smooth = true) => {
        messagesEndRef.current?.scrollIntoView({
            behavior: smooth ? 'smooth' : 'auto'
        });
    };

    useEffect(() => {
        if (isOpen) {
            scrollToBottom(false);
        }
    }, [messages, isOpen]);

    const handleSendMessage = async () => {
        if (!newMessage.trim() || !accessToken) return;

        // Add the message optimistically
        const tempMessage = {
            MessageID: `temp-${Date.now()}`,
            Content: newMessage,
            Sender: 'Me',
            Timestamp: new Date().toISOString(),
            Status: 'sending'
        };

        setMessages(prev => [...prev, tempMessage]);
        setNewMessage('');
        scrollToBottom();

        try {
            await fetch(`${apiBaseUrl}/messages/send`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Content: newMessage,
                    Sender: 'Me'
                })
            });

            await fetchMessages();
        } catch (err) {
            setError(err.message);
            // Remove the temp message if sending fails
            setMessages(prev => prev.filter(msg => msg.MessageID !== tempMessage.MessageID));
        }
    };

    // Send on Enter press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const formatMessageDate = (timestamp) => {
        return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    };

    // Group messages by date for displaying
    const groupMessagesByDate = (messages) => {
        const groups = [];
        let currentDate = null;
        let currentGroup = [];

        messages.forEach(message => {
            const messageDate = new Date(message.Timestamp).toDateString();

            if (messageDate !== currentDate) {
                if (currentGroup.length > 0) {
                    groups.push({ date: currentDate, messages: currentGroup });
                }
                currentDate = messageDate;
                currentGroup = [message];
            } else {
                currentGroup.push(message);
            }
        });

        if (currentGroup.length > 0) {
            groups.push({ date: currentDate, messages: currentGroup });
        }

        return groups;
    };

    const MessageBubble = ({ message, showAvatar = true }) => {
        const isUser = message.Sender === 'Me';

        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: isUser ? 'flex-end' : 'flex-start',
                    alignItems: 'flex-end',
                    mb: 1,
                    gap: 1
                }}
            >
                {!isUser && showAvatar && (
                    <Avatar
                        sx={{
                            bgcolor: 'primary.main',
                            width: 28,
                            height: 28,
                            fontSize: '0.875rem'
                        }}
                    >
                        C
                    </Avatar>
                )}
                {!isUser && !showAvatar && <Box sx={{ width: 28 }} />}
                <Box sx={{ maxWidth: '80%' }}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 1.5,
                            bgcolor: isUser ? 'primary.main' : 'grey.100',
                            borderRadius: 2,
                            borderTopLeftRadius: !isUser && showAvatar ? 0 : 2
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                color: isUser ? 'primary.contrastText' : 'text.primary',
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                lineHeight: 1.4
                            }}
                        >
                            {message.Content}
                        </Typography>
                    </Paper>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        mt: 0.5,
                        justifyContent: isUser ? 'flex-end' : 'flex-start'
                    }}>
                        <Typography
                            variant="caption"
                            sx={{
                                color: 'text.secondary',
                                fontSize: '0.7rem'
                            }}
                        >
                            {formatMessageDate(message.Timestamp)}
                        </Typography>
                        {isUser && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {message.Status === 'sending' && (
                                    <CheckIcon sx={{ fontSize: 12, color: 'text.secondary' }} />
                                )}
                                {message.Status === 'delivered' && (
                                    <CheckCircleIcon sx={{ fontSize: 12, color: 'success.main' }} />
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        );
    };

    const handleTyping = () => {
        setIsTyping(true);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const timeout = setTimeout(() => {
            setIsTyping(false);
        }, 1000);
        setTypingTimeout(timeout);
    };

    return (
        <Box sx={{ position: 'fixed', bottom: 24, right: 24, zIndex: 9999 }}>
            <Fab
                color="primary"
                onClick={() => {
                    setIsOpen(!isOpen);
                    if (!isOpen) {
                        setTimeout(() => {
                            inputRef.current?.focus();
                        }, 100);
                    }
                }}
                aria-label="messages"
                sx={{
                    boxShadow: 3,
                    '&:hover': {
                        transform: 'scale(1.05)'
                    },
                    transition: 'transform 0.2s'
                }}
            >
                <MessageIcon />
            </Fab>

            <Collapse in={isOpen} timeout={300}>
                <Paper
                    ref={popupRef}
                    elevation={6}
                    sx={{
                        position: 'absolute',
                        bottom: 72,
                        right: 0,
                        width: '360px',
                        height: '480px',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 3,
                        overflow: 'hidden',
                        bgcolor: 'background.paper'
                    }}
                >
                    <Box sx={{
                        px: 2,
                        py: 1.5,
                        bgcolor: 'primary.main',
                        color: 'primary.contrastText',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        boxShadow: 1
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                            <Avatar sx={{ width: 40, height: 40 }}>C</Avatar>
                            <Box>
                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                    Your Coach
                                </Typography>
                                {isTyping && (
                                    <Typography variant="caption" sx={{ color: 'primary.light' }}>
                                        typing...
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                                size="small"
                                sx={{ color: 'inherit' }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={() => setIsOpen(false)}
                                sx={{ color: 'inherit' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box sx={{
                        flex: 1,
                        overflowY: 'auto',
                        bgcolor: 'background.default',
                        p: 2
                    }}>
                        {loading ? (
                            <Box display="flex" justifyContent="center" p={4}>
                                <CircularProgress size={32} />
                            </Box>
                        ) : error ? (
                            <Typography color="error" align="center">
                                Error loading messages. Please try again.
                            </Typography>
                        ) : (
                            groupMessagesByDate(messages).map((group, groupIndex) => (
                                <Box key={group.date}>
                                    <Typography
                                        variant="caption"
                                        align="center"
                                        sx={{
                                            display: 'block',
                                            textAlign: 'center',
                                            color: 'text.secondary',
                                            my: 2,
                                            fontSize: '0.7rem'
                                        }}
                                    >
                                        {new Date(group.date).toLocaleDateString(undefined, {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })}
                                    </Typography>
                                    {group.messages.map((message, index) => (
                                        <MessageBubble
                                            key={message.MessageID}
                                            message={message}
                                            showAvatar={
                                                message.Sender !== 'Me' &&
                                                (index === 0 ||
                                                    group.messages[index - 1]?.Sender === 'Me')
                                            }
                                        />
                                    ))}
                                </Box>
                            ))
                        )}
                        <div ref={messagesEndRef} />
                    </Box>

                    <Box sx={{
                        p: 2,
                        bgcolor: 'background.paper',
                        borderTop: '1px solid',
                        borderColor: 'divider'
                    }}>
                        <TextField
                            fullWidth
                            size="small"
                            multiline
                            maxRows={4}
                            placeholder="Type a message..."
                            value={newMessage}
                            onChange={(e) => {
                                setNewMessage(e.target.value);
                                handleTyping();
                            }}
                            onKeyPress={handleKeyPress}
                            inputRef={inputRef}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 3,
                                    backgroundColor: 'grey.100'
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleSendMessage}
                                            color="primary"
                                            disabled={!newMessage.trim()}
                                            size="small"
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Paper>
            </Collapse>
        </Box>
    );
};

export default MessagePopup;