import React, { useState } from 'react';
import { Button, Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

/**
 * Example usage:
 * <StripeSubscription
 *   accessToken={accessToken}
 *   userEmail={userEmail}
 *   onSubscriptionComplete={() => {...}}
 *   currency="USD"
 * />
 */

const loadStripeScript = () => {
  return new Promise((resolve) => {
    // Check if Stripe script is already present
    if (window.Stripe) {
      resolve(true);
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3';
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

const StripeSubscription = ({
  accessToken,
  userEmail,
  onSubscriptionComplete,
  currency = 'USD'
}) => {
  const [loading, setLoading] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  // Simple test mode detection: if user uses @a.com => test
  const isTestMode = userEmail?.toLowerCase().endsWith('@a.com');

  // Typically you'd have a separate test vs production publishableKey
  const STRIPE_PUBLISHABLE_KEY = isTestMode
    ? 'pk_test_1234abc...'  // Replace with your test key
    : 'pk_live_1234xyz...'; // Replace with your live key

  /**
   * plan => 'monthly' or 'yearly'
   * planType => monthly_usd, yearly_usd, monthly_inr, yearly_inr
   */
  const handleSubscription = async (plan) => {
    const planType = `${plan.toLowerCase()}_${currency.toLowerCase()}`;

    try {
      setLoading(true);
      setProcessingMessage('Creating Stripe Checkout Session...');

      // Attempt to load Stripe script
      const scriptLoaded = await loadStripeScript();
      if (!scriptLoaded) {
        alert('Stripe JS failed to load. Please try again.');
        setLoading(false);
        setProcessingMessage('');
        return;
      }

      // 1) Create a checkout session on the server
      const createSessionRes = await axios.post(
        'https://api2.enduroco.in/settings/subscription/stripe/create-session',
        { planType, isTestMode },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          params: {
            email: userEmail,
            isTestMode
          }
        }
      );

      const { sessionId } = createSessionRes.data;
      if (!sessionId) {
        throw new Error('No sessionId returned from server.');
      }

      // 2) Initialize Stripe using the publishable key
      const stripe = new window.Stripe(STRIPE_PUBLISHABLE_KEY);

      // 3) Redirect to Stripe Checkout
      setProcessingMessage('Redirecting to Stripe Checkout...');
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        throw new Error(error.message);
      }
      // After successful payment, user returns to successUrl on the server side

    } catch (error) {
      console.error('Stripe subscription error:', error);
      alert(error?.message || 'Failed to create or redirect to Stripe checkout.');
      setLoading(false);
      setProcessingMessage('');
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      {isTestMode && (
        <Box
          sx={{
            mb: 2,
            p: 2,
            bgcolor: 'warning.light',
            borderRadius: 1,
            border: 1,
            borderColor: 'warning.main'
          }}
        >
          <Typography variant="body2" color="warning.dark">
            Test Mode Active - Using test Stripe keys
          </Typography>
        </Box>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <CircularProgress />
          {processingMessage && (
            <Typography variant="body2">
              {processingMessage}
            </Typography>
          )}
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Choose your subscription plan:
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubscription('monthly')}
            >
              {currency === 'INR' ? 'Monthly - ₹990' : 'Monthly - $12.99'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubscription('yearly')}
            >
              {currency === 'INR'
                ? 'Yearly - ₹9,599 (Save 20%)'
                : 'Yearly - $99 (Save ~36%)'}
            </Button>
          </Box>
        </>
      )}

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowSuccess(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Subscription activated successfully! (Stripe)
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default StripeSubscription;