import React from 'react';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import PoolIcon from '@mui/icons-material/Pool';
import FitnessCenter from '@mui/icons-material/FitnessCenter';

export const getIntensityColor = (intensity, theme) => {
  switch (intensity?.toLowerCase()) {
    case 'vo2max':
      return theme.palette.error.main;
    case 'threshold':
      return theme.palette.warning.main;
    case 'endurance':
      return theme.palette.success.main;
    case 'strength':
      return theme.palette.info.main;
    case 'core':
      return theme.palette.secondary.main;
    case 'rest':
      return theme.palette.text.disabled;
    case 'anaerobic':
      return theme.palette.error.dark;
    default:
      return theme.palette.grey[500];
  }
};

export const getDisciplineIcon = (discipline, color) => {
  const iconProps = {
    sx: { 
      fontSize: '0.9rem',
      mr: 0.5,
      color: color 
    }
  };

  switch (discipline?.toLowerCase()) {
    case 'cycling':
      return <DirectionsBikeIcon {...iconProps} />;
    case 'running':
      return <DirectionsRunIcon {...iconProps} />;
    case 'swimming':
      return <PoolIcon {...iconProps} />;
    case 'strength':
      return <FitnessCenter {...iconProps} />;
    default:
      return null;
  }
};
