import axios from 'axios';

const validateTimeBasedSettings = (settings) => {
    const validDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const cleanedSettings = {};
    
    Object.entries(settings).forEach(([day, value]) => {
        if (validDays.includes(day)) {
            cleanedSettings[day] = value;
        }
    });
    
    return cleanedSettings;
};

export const updateWorkoutSettings = async (discipline, workoutVolume, accessToken, userEmail) => {
    let cleanWorkoutVolume = {
        volumeSelectionMethod: workoutVolume.volumeSelectionMethod,
        settingEnabled: true
    };

    // Always include these manual settings regardless of volume selection method
    cleanWorkoutVolume.manual = {
        weekstart: String(workoutVolume.manual.weekstart),
        targetformpct: String(workoutVolume.manual.targetformpct || '0')
    };

    if (workoutVolume.volumeSelectionMethod === 'timebased') {
        const cleanedTimeSettings = validateTimeBasedSettings(
            workoutVolume.timeBased?.volumeSelectionTimeBasedSettings || {}
        );
        
        cleanWorkoutVolume = {
            ...cleanWorkoutVolume,
            timeBased: {
                volumeSelectionTimeBasedSettings: cleanedTimeSettings
            }
        };
    } else {
        // For manual mode, add the numberofdaysweekly setting
        cleanWorkoutVolume.manual = {
            ...cleanWorkoutVolume.manual,
            numberofdaysweekly: String(workoutVolume.manual.numberofdaysweekly)
        };
    }

    const response = await axios.patch(
        'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
        {
            discipline,
            workoutVolume: cleanWorkoutVolume
        },
        {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            params: { email: userEmail }
        }
    );

    return response.data;
};
