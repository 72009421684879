import React, { useState } from 'react';
import { Box, IconButton, Paper, Collapse, Typography } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const CollapsibleSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Paper 
      sx={{ 
        width: '100%',
        backgroundColor: 'background.paper',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          cursor: 'pointer',
          '&:hover': {
            bgcolor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton size="small">
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default CollapsibleSection;
