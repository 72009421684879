import React from "react";
import Grid from "@mui/material/Grid";
import moment from "moment";
import CalendarDay from "./CalendarDay";
import WeeklySummary from "./WeeklySummary";
import Box from "@mui/material/Box";
import { useCalendarData } from "../calendarDataProvider";

const CalendarBody = ({
  id,
  currentWeek,
  events,
  setOpenWorkoutView,
  setSelectedEvent,
  isLastWeek
}) => {
  const { weeklyLoads } = useCalendarData();
  const startOfWeek = currentWeek.clone().startOf("isoWeek");
  const endOfWeek = currentWeek.clone().endOf("isoWeek");
  const days = [];

  let day = startOfWeek;
  while (!day.isAfter(endOfWeek, "day")) {
    days.push(day.clone());
    day.add(1, "day");
  }

  const weekIdentifier = startOfWeek.format("YYYY-MM-DD");

  return (
    <Box sx={{ marginBottom: 0 }}>
      <Grid 
        container 
        sx={{ 
          display: 'flex',
          width: '100%',
          flexWrap: 'nowrap',
          minHeight: '100px'
        }} 
        id={id} 
        data-week={weekIdentifier}
      >
        {days.map((day, index) => (
          <CalendarDay
            key={day.format()}
            day={day}
            events={events.filter((event) =>
              moment(event.date).isSame(day, "day")
            )}
            setOpenWorkoutView={setOpenWorkoutView}
            setSelectedEvent={setSelectedEvent}
          />
        ))}
      </Grid>
      <WeeklySummary 
        weekStart={startOfWeek} 
        events={events}
        isLastWeek={isLastWeek}
        weeklyLoads={weeklyLoads}
      />
    </Box>
  );
};

export default CalendarBody;
